
import { useStore } from '@/store';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuestionsOverview',
  computed: {
    ...mapState(useStore, ['serverState']),
    ...mapState(useStore, {
      activeQuiz(store) {
        if (!store.serverState) return;

        const { activeQuiz: activeQuizId } = store.serverState;
        if (!activeQuizId) return;

        return Object.fromEntries(
          Object.entries(store.serverState.quizes).filter(
            ([quizId]) => quizId === activeQuizId
          )
        );
      },
      openQuizes(store) {
        if (!store.serverState) return;

        return Object.fromEntries(
          Object.entries(store.serverState.quizes).filter(
            ([, quiz]) => !quiz.active && !quiz.disabled && !quiz.played
          )
        );
      },
      playedQuizes(store) {
        if (!store.serverState) return;

        return Object.fromEntries(
          Object.entries(store.serverState.quizes).filter(
            ([, quiz]) => !quiz.active && !quiz.disabled && quiz.played
          )
        );
      },
      disabledQuizes(store) {
        if (!store.serverState) return;

        return Object.fromEntries(
          Object.entries(store.serverState.quizes).filter(
            ([, quiz]) => !quiz.active && quiz.disabled
          )
        );
      },
      activeQuizNumber() {
        return Object.keys(this.playedQuizes).length + 1;
      },
      totalQuizes(store) {
        if (!store.serverState) return 0;

        return Object.values(store.serverState.quizes).filter(
          (quiz) => !quiz.disabled
        ).length;
      },
    }),
  },
});
